import React, { useCallback, useEffect } from 'react';

import {
  Dropdown,
  Tabs,
  TabsAlignment as ALIGNMENT,
  TabsSkin as SKIN,
  TabsVariant as VARIANT,
} from 'wix-ui-tpa/cssVars';

import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../contexts/main/biInterfaces';

import challengeSettings from '../../settingsParams';
import {
  CategoriesTabsAlignment,
  CategoriesTabsMobileTypeOptions,
  CategoriesTabsTypeOptions,
  ChallengeSettingsParams,
} from '../../Settings/challengeSettings/challengeSettings.types';
import { Category } from '@wix/ambassador-achievements-categories-v3-category/types';

import { classes, st } from './CategoriesMenu.st.css';

import { isProgramWidget } from '../../../../selectors/isProgramWidget';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useCategories } from '../../../../contexts/storage-contexts/Categories';

type CategoriesMenuProps = {
  tabsItems: Category[];
  categories: Category[];
  onChange: (categoryID: string) => void;
};

function useHandleActiveTab(categories: Category[]) {
  const [activeTab, setActiveTab] = React.useState(0);
  const { categoryToPreview } = useCategories();
  useEffect(() => {
    if (categoryToPreview) {
      const index = categories.findIndex(
        (category) => category.id === categoryToPreview,
      );
      if (index !== -1) {
        setActiveTab(index);
      }
    }
  }, [categoryToPreview, categories]);
  return {
    activeTab,
    setActiveTab,
  };
}

const CategoriesMenu: FCWithChildren<CategoriesMenuProps> = (props) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile, widgetId, isRTL } = useEnvironment();
  const settings = useSettings();
  const bi = useBi();
  const { onChange, tabsItems } = props;
  const isDesktopTabsView =
    settings.get(
      challengeSettings[ChallengeSettingsParams.CategoriesTabsType],
    ) === CategoriesTabsTypeOptions.Tabs;
  const isMobileTabsView =
    isMobile &&
    settings.get(
      challengeSettings[ChallengeSettingsParams.CategoriesTabsMobileType],
    ) === CategoriesTabsMobileTypeOptions.Tabs;
  const isMobileDropdownView =
    isMobile &&
    settings.get(
      challengeSettings[ChallengeSettingsParams.CategoriesTabsMobileType],
    ) === CategoriesTabsMobileTypeOptions.Dropdown;

  const { activeTab, setActiveTab } = useHandleActiveTab(tabsItems);

  const getAlignment = useCallback((alignment: CategoriesTabsAlignment) => {
    switch (alignment) {
      case CategoriesTabsAlignment.Left:
        return ALIGNMENT.left;
      case CategoriesTabsAlignment.Right:
        return ALIGNMENT.right;
      case CategoriesTabsAlignment.Center:
      default:
        return ALIGNMENT.center;
    }
  }, []);
  const variantValue = React.useMemo(() => {
    return settings.get(
      challengeSettings[ChallengeSettingsParams.CategoriesTabsFitToWidth],
    ) || isMobile
      ? VARIANT.fullWidth
      : VARIANT.fit;
  }, [settings, isMobile]);
  const alignmentValue = React.useMemo(() => {
    return getAlignment(
      settings.get(
        challengeSettings[ChallengeSettingsParams.CategoriesTabsAlignment],
      ),
    );
  }, [getAlignment, settings]);

  const onDropdownChange = useCallback(
    ({ id }): void => {
      for (let i = 0; i < tabsItems.length; i++) {
        if (tabsItems[i].id === id) {
          setActiveTab(i);
          return;
        }
      }
    },
    [tabsItems, setActiveTab],
  );

  useEffect(() => {
    bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.CategoryTabChange,
      }),
    );

    onChange(tabsItems[activeTab]?.id);
  }, [activeTab, bi, onChange, tabsItems]);

  if (
    experiments.enabled('spec.programs.ProgramListSettingsRedesign') &&
    tabsItems.length <= 1
  ) {
    return null;
  }

  if (isMobileDropdownView) {
    return (
      <Dropdown
        upgrade
        className={st(classes.dropdown, {
          mobile: isMobile,
          isProgramPage: !isProgramWidget(widgetId),
        })}
        theme={'box' as any}
        placeholder={t('categories-menu.dropdown-placeholder')}
        initialSelectedId={tabsItems[activeTab].id}
        onChange={onDropdownChange}
        options={tabsItems}
      />
    );
  }

  if (isDesktopTabsView || isMobileTabsView) {
    return (
      <div
        dir={isRTL ? 'rtl' : 'auto'}
        className={st(classes.tabsWrapper, { mobile: isMobile })}
      >
        <Tabs
          activeTabIndex={activeTab}
          className={st(classes.tabs, { mobile: isMobile })}
          items={tabsItems}
          onTabClick={setActiveTab}
          variant={variantValue}
          alignment={alignmentValue}
          skin={SKIN.fitUnderline}
          isTablist
        />
      </div>
    );
  }

  return null;
};

export default CategoriesMenu;
