import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './EmptyList.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IEmptyListProps {}

const EmptyList: FCWithChildren<IEmptyListProps> = () => {
  const { t } = useTranslation();

  return (
    <section className={classes.root}>
      <div className={classes.text}>{t('challenge.empty-list')}</div>
    </section>
  );
};

export default EmptyList;
