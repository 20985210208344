import React from 'react';
import {
  isUserCompleted,
  isUserInPaymentState,
  isUserJoinedAlready,
} from '../../../contexts/User/helpers/userTypeHandlers';

import { Badge, BadgePriority as BADGE_PRIORITY } from 'wix-ui-tpa/cssVars';

import {
  ChallengeSummary,
  State as ChallengeState,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes, st } from './Badge.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../settingsParams';
import { FCWithChildren } from '@wix/challenges-web-library';
import { getParticipantStateFromSummary } from './helpers/GetParticipantStateFromSummary';

export interface IBadgeProps {
  summary: ChallengeSummary;
  challengeTransition?: ChallengeState;
  isPrivate?: boolean;
  badgeSkin?: BADGE_PRIORITY;
  badgeText?: string;
  getBadgeStrategy?(
    participantState: ParticipantState,
    isPrivate?: boolean,
  ): { tKey: string; badge?: BADGE_PRIORITY; settingsKey?: any };
  className?: string;
}

const getBadgeProps = (
  programSummary: ChallengeSummary,
  isPrivate: boolean,
): { tKey?: string; badge: BADGE_PRIORITY; settingsKey?: any } => {
  const participantState = getParticipantStateFromSummary(programSummary);

  if (ParticipantState.SUSPENDED === participantState) {
    return {
      badge: BADGE_PRIORITY.light,
      tKey: 'challenge.participant.paused',
    };
  }
  if (
    participantState === ParticipantState.PAYMENT_STARTED_OFFLINE ||
    programSummary.participation?.offlineTrxId
  ) {
    return {
      badge: BADGE_PRIORITY.default,
      tKey: 'challenge.participant.offline-payment-pending',
    };
  }
  if (isUserCompleted(participantState)) {
    return {
      settingsKey: settingsParams.labelChallengeCompleted,
      badge: BADGE_PRIORITY.primary,
    };
  }

  if (isUserJoinedAlready(participantState)) {
    return {
      settingsKey: settingsParams.labelParticipant,
      badge: BADGE_PRIORITY.primary,
    };
  }

  if (participantState === ParticipantState.JOIN_REQUESTED) {
    return {
      settingsKey: settingsParams.labelPendingRequest,
      badge: BADGE_PRIORITY.default,
    };
  }

  if (isPrivate && isUserInPaymentState(participantState)) {
    return {
      settingsKey: settingsParams.labelJoinApproved,
      badge: BADGE_PRIORITY.primary,
    };
  }

  if (ParticipantState.JOIN_REJECTED === participantState) {
    return {
      settingsKey: settingsParams.labelJoinRejected,
      badge: BADGE_PRIORITY.default,
    };
  }

  return null;
};

export const ChallengeBadge: FCWithChildren<IBadgeProps> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();

  const participantState = getParticipantStateFromSummary(props.summary);

  const isCompletedChallenge = isUserCompleted(participantState);

  const badgeProps = participantState
    ? props.getBadgeStrategy?.(participantState, props.isPrivate) ||
      getBadgeProps(props.summary, props.isPrivate)
    : null;

  if (!badgeProps) {
    return <div data-hook="challenge-badge" />;
  }

  /*
    We have settings for background and text colors for badges from the List.
    For MA (and other places) default site colors should be used.
    So if we haven't `getBadgeStrategy` prop, it means, that this badge is from the List, and we will
      add the `list` css state for this case, and use the colors from settings.
   */

  return (
    <div
      data-hook="challenge-badge"
      className={st(
        classes.root,
        {
          list: !props.getBadgeStrategy,
          primary: badgeProps.badge === BADGE_PRIORITY.primary,
          completed: isCompletedChallenge,
        },
        props.className,
      )}
    >
      <Badge
        priority={props.badgeSkin || badgeProps.badge}
        className={classes.badge}
      >
        <span className={classes.text}>
          {props.badgeText || null}
          {!props.badgeText && badgeProps.tKey ? t(badgeProps.tKey) : null}
          {badgeProps.settingsKey ? settings.get(badgeProps.settingsKey) : null}
        </span>
      </Badge>
    </div>
  );
};
